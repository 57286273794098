


$('.top-works-list').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: '8%',

    responsive: [
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                centerPadding: '15%',
            }
        }
    ]
});